export enum MediaCategory {
  Xray = 'Xray',
  OtherProviderNote = 'OtherProviderNote',
  InsuranceCard = 'InsuranceCard',
  LabReport = 'LabReport',
  MedicalRecords = 'MedicalRecords',
  VisitNoteImage = 'VisitNoteImage',
  ProfilePicture = 'ProfilePicture',
  FinalizedVisitNote = 'FinalizedVisitNote',
  Identification = 'Identification',
  ConsentForms = 'ConsentForms',
  MedicalNotes = 'MedicalNotes',
  RadiologyImages = 'RadiologyImages',
  EOB = 'EOBDoc',
}

export enum MediaOriginatorType {
  Org = 'Org',
  Patient = 'Patient',
  Billing = 'Billing',
}

export type MediaCategoryType = {
  label: string;
  value: string;
  group: string;
};

export const imagingTypes = [{
  label: 'CT Scan',
  value: 'CTScan',
}, {

  label: 'MRI',
  value: 'MRI',
}, {
  label: 'Ultrasound',
  value: 'Ultrasound',
}, {
  label: 'X-ray',
  value: 'Xray',
}, {
  label: 'Quantaflo',
  value: 'Quantaflo',
}, {
  label: 'DPN',
  value: 'DPN',
}, {
  label: 'DM Shoes',
  value: 'DMShoes',
}];

export const reportTypes = [{
  label: 'EMG',
  value: 'EMG',
}, {
  label: 'Lab Report',
  value: 'LabReport',
}, {
  label: 'Operative Report',
  value: 'OperativeReport',
}, {
  label: 'Pathology',
  value: 'Pathology',
}];

export const legalDocsTypes = [{
  label: 'Approved Authorization',
  value: 'ApprovedAuthorization',
}, {
  label: 'Eligibility',
  value: 'Eligibility',
}, {
  label: 'Injury Paperwork',
  value: 'InjuryPaperwork',
}, {
  label: 'PPO Verification',
  value: 'PPOVerification',
}, {
  label: 'Requested Authorization',
  value: 'RequestedAuthorization',
}, {
  label: "Worker's Compensation Paperwork",
  value: 'WorkersCompensationPaperwork',
}];

export const referralDocsTypes = [{
  label: 'Referral',
  value: 'Referral',
}];

export const identificationDocsTypes = [
  {
    label: 'ID Card',
    value: 'Identification',
  }, {
    label: 'Insurance Card',
    value: 'InsuranceCard',
  }];

export const noteTypes = [{
  label: 'Consult Note',
  value: 'ConsultNote',
}, {
  label: 'Other Provider Note',
  value: 'OtherProviderNote',
}];

export const otherTypes = [{
  label: 'Appeal',
  value: 'Appeal',
}, {
  label: 'Super Bill',
  value: 'SuperBill',
}, {
  label: 'Consent',
  value: 'Consent',
}, {
  label: 'Lab Slip',
  value: 'LabSlip',
}, {
  label: 'OARRS/KASPER',
  value: 'OarrsKasper',
}, {
  label: 'Other',
  value: 'Other',
}, {
  label: 'Prescription',
  value: 'Prescription',
}];

export const mediaCategoryGroupings = [
  { group: 'Imaging', values: imagingTypes },
  { group: 'Report', values: reportTypes },
  { group: 'Legal Document', values: legalDocsTypes },
  { group: 'Referral', values: referralDocsTypes },
  { group: 'Notes', values: noteTypes },
  { group: 'Identification', values: identificationDocsTypes },
  { group: 'Other', values: otherTypes },
];
