import {
  useState,
  useCallback,
} from 'react';

type Storage = 'session' | 'local';

export function useStorageState<T>(
  key: string,
  defaultValue?: T,
  type: Storage = 'session',
): [T, (value: T) => void] {
  const storage = window[`${type}Storage`];
  const storageValue = JSON.parse(storage.getItem(key) ?? 'null');
  const [value, setValue] = useState<T>(storageValue ?? defaultValue);

  const dispatcher = useCallback((value: T) => {
    setValue(value);
    storage.setItem(key, JSON.stringify(value));
  }, [key, setValue, storage]);

  return [value, dispatcher];
}
