import React, {
  FunctionComponent,
  LazyExoticComponent,
  Suspense,
} from 'react';
import FullScreenSpinner from 'core/components/FullScreenSpinner';

type Props = {
// using `any` as some of those components could have
// specific Properties
  Component: LazyExoticComponent<FunctionComponent<any>>;
};

export default function LazyLoadComponent({ Component }: Props) {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <Component />
    </Suspense>
  );
}
