import {
  PatientSummaryDTO,
  VisitNoteBillDTO,
} from 'dtos';
import {
  VisitNoteBillingCode,
} from 'views/EMR/components/ServiceLookup/type';

export type BlobPayload = {
  mediaBlobUrl: string;
};

export enum SECTIONS {
  SUBJECTIVE = 'additionalHPI',
  OBJECTIVE = 'physicalExam',
  ASSESSMENT = 'additionalAssessment',
  PLAN = 'plan'
}

export type Section = {
  key: keyof typeof SECTIONS;
  title: string;
  text: string;
};

export type RoboscribePayload = {
  file: File;
};

export type RoboscribeResult = {
  note: {
    title: string;
    sections: Section[];
  };
};

export type TranscriptItem = {
  text: string;
  speaker_type: string;
  start_offset_ms: number;
  end_offset_ms: number;
};

export type TranscribeResult = {
  transcript: TranscriptItem[];
};

export type Illness = {
  title: string;
  hpiAttributes: string[];
};

export type HPI = {
  hpiFromIntakeElements: Illness[];
};

export enum BillingState {
  NotReady = 'BillingNotReady',
  Review = 'BillingReview',
  ReviewCompleted = 'BillingReviewCompleted',
}

export type VisitNote = {
  patientId?: number;
  billingState?: string;
  encounterId: number;
  visitNoteId: number;
  physicalExam: string;
  radiology: string;
  plan: string;
  additionalAssessment?: string;
  hpi: HPI;
  additionalHPI?: string;
  hpiInParagraphForm: string | null;
  patient: PatientSummaryDTO;
  addendum?: string;
  isFinalized?: boolean;
  finalizedVisitNoteMultiMediaId?: number | null;
  state: string;
  updatedOn?: string;
  bill: Omit<VisitNoteBillDTO, 'serviceCodes'> & {
    serviceCodes?: VisitNoteBillingCode[];
  };
};
