import React, {
  ReactNode,
} from 'react';
import clsx from 'clsx';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionProps,
} from '@mui/material/Accordion/Accordion';

type Props = AccordionProps & {
  header: ReactNode;
  className?: string;
  detailsClasses?: Record<string, string>;
  headerClasses?: Record<string, string>;
  disabled?: boolean;
  expandIcon?: ReactNode;
  children: ReactNode;
};

export default function Accordion({
  header,
  children,
  className,
  detailsClasses,
  headerClasses,
  disabled,
  expandIcon = <ExpandMoreIcon />,
  ...props
}: Props) {
  const classes = clsx(
    'populate-accordion',
    { '!bg-transparent !pointer-events-none': disabled },
    className,
  );

  return (
    <MuiAccordion
      disabled={disabled}
      className={classes}
      {...props}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        classes={headerClasses}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails classes={detailsClasses}>
        {children}
      </AccordionDetails>
    </MuiAccordion>

  );
}
