import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  postApiToDoRequestToDoList,
} from 'endpoints';
import {
  CommentActivity,
  ToDo,
} from 'types/toDo';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

import {
  AsyncHandlerResult,
  CommentResponsePayload,
  ListQueryProps,
  ToDoSettings,
  ToDoSettingsResponse,
  ToDoUpdateHandlerProps,
  UpdateToDoRequestPayload,
  UpdateToDoResponsePayload,
} from 'pages/Dashboard/pages/Todos/types';
import {
  commentOnToDo,
  createToDo,
  getDetailedToDo,
  getToDoComments,
  getToDoSettings,
  updateToDo,
} from 'pages/Dashboard/pages/Todos/transport';
import {
  normalizeToDos,
  normalizeValidToDoStatuses,
} from 'pages/Dashboard/pages/Todos/normalize';
import {
  SettingsDTO,
} from 'dtos';
import {
  normalizeFilterableSettings,
} from 'core/Filters/util';
import {
  useNotify,
} from 'core/hooks';

export function useToDos(query: ListQueryProps): UseQueryResult<ToDo[], Error> {
  const { patientId } = query;
  return useQuery<ToDo[], Error>(
    ['to-dos', patientId],
    async () => {
      const response = await postApiToDoRequestToDoList({
        patientId,
        includeReferencedObjects: true,
      });
      return normalizeToDos(response);
    },
  );
}

export function useDetailedToDo(toDoId: number): UseQueryResult<ToDo, Error> {
  return useQuery<ToDo, Error>(
    `to-do/${toDoId}`,
    () => getDetailedToDo(toDoId),
    { enabled: toDoId !== 0 },
  );
}

export function useToDoComments(
  toDoId: number,
  enabled: boolean,
): UseQueryResult<CommentActivity[], Error> {
  return useQuery<CommentActivity[], Error>(
    ['to-do-comments', toDoId],
    () => getToDoComments(toDoId),
    { enabled },
  );
}

export function useToDoSettings(): ToDoSettings {
  const { data } = usePrefetchedData<ToDoSettingsResponse>({
    key: 'to-do-settings',
    fetchFn: getToDoSettings,
  });
  const categories = data?.toDoCategorySettings ?? [];
  const statuses = data?.toDoStatusStartingStateSettings ?? [];
  return {
    categories: categories.map((item) => item.category as string),
    statuses: normalizeValidToDoStatuses(
      statuses.map((item) => item.state),
    ),
  };
}

export function useCreateToDo(): UseMutationResult<ToDo, Error, Partial<ToDo>> {
  return useMutation<ToDo, Error, Partial<ToDo>>({
    mutationFn: (data) => createToDo(data),
  });
}

export function useCommentOnToDo():
  UseMutationResult<CommentResponsePayload, Error, Partial<ToDo>> {
  return useMutation<CommentResponsePayload, Error, Partial<ToDo>>({
    mutationFn: (data) => commentOnToDo(data),
  });
}

export function useUpdateToDoHandler({
  errorMessage = 'Oops! Something went wrong! To-do was not updated. Try again!',
  successMessage = 'To-do updated successfully',
}: ToDoUpdateHandlerProps): AsyncHandlerResult {
  const notify = useNotify();
  const { mutateAsync, isLoading } = useMutation<
    UpdateToDoResponsePayload,
    Error,
    UpdateToDoRequestPayload>(updateToDo);

  const asyncHandler = async (payload: UpdateToDoRequestPayload) => {
    try {
      await mutateAsync(payload);
    } catch (e) {
      console.error(e);

      notify({
        variant: 'error',
        message: errorMessage,
      });
    }

    notify({
      variant: 'success',
      message: successMessage,
    });
  };

  return { isLoading, asyncHandler };
}

export function useToDosFilterMetadata() {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'to-do-settings',
    fetchFn: getToDoSettings,
  });
  return normalizeFilterableSettings(data?.filterableSettings ?? []);
}
