import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  ComponentType,
  PropsWithChildren,
  useRef,
  RefObject,
  ReactNode,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import IconButton from '@mui/material/IconButton';
import {
  useLocation,
} from 'react-router-dom';
import Drawer, {
  DrawerClasses,
} from '@mui/material/Drawer';
import isNil from 'lodash/isNil';
import clsx from 'clsx';
import BackwardIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import ToolTip from 'core/components/Tooltip';

type Part = 'header';

export type Entity = Record<string, any> & {
  id: number | string;
};

export type SidePanelComponentProps = {
  entity: Entity;
  onClose?: () => void;
  extensionRef?: RefObject<HTMLDivElement>;
  slots?: Partial<Record<Part, ReactNode>>;
};

export type SidePanelProps = {
  entity: Entity;
  Component: ComponentType<SidePanelComponentProps> | null;
  classes?: Partial<DrawerClasses>;
  panelId?: string;
  previousPanel?: SidePanelProps | null;
};

type ContextValues = {
  openSidePanel: (props: SidePanelProps) => void;
  closeSidePanel: () => void;
  props?: SidePanelProps | null;
};

export const SidePanelContext = createContext<ContextValues>({
  openSidePanel: () => {},
  closeSidePanel: () => {},
  props: null,
});

export default function DetailedViewContextProvider({ children }: PropsWithChildren) {
  const [props, setProps] = useState<SidePanelProps>({
    entity: { id: 0 },
    Component: null,
  });
  const panelExtensionRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const openSidePanel = (_props: SidePanelProps) => {
    setProps(_props);
    setOpen(true);
  };

  const closeSidePanel = () => { setOpen(false); };

  const value = useMemo(() => ({
    openSidePanel,
    closeSidePanel,
    props: cloneDeep(props),
  }), [openSidePanel, closeSidePanel, props]);

  useEffect(() => {
    if (!isNil(props.Component)) {
      closeSidePanel();
      setProps({ entity: { id: 0 }, Component: null });
    }
  }, [location.pathname]);

  const { Component, entity, classes } = props;

  return (
    <SidePanelContext.Provider value={value}>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeSidePanel}
        hideBackdrop
        style={{ height: 'calc(100%-0.875rem)!important' }}
        classes={{
          ...classes,
          root: clsx(classes?.root, 'detailed-entity-drawer has-extension !overflow-[initial]'),
        }}
        disableEnforceFocus
        disableScrollLock
        disableAutoFocus
        disableRestoreFocus
      >
        <div className="w-full h-full flex relative">
          {!isNil(Component) && (
            <Component
              entity={entity}
              onClose={closeSidePanel}
              slots={{
                header: !isNil(props.previousPanel) && (
                  <ToolTip title="Go Back">
                    <IconButton
                      size="small"
                      className="!mr-2"
                      onClick={() => {
                        openSidePanel(props.previousPanel!);
                      }}
                    >
                      <BackwardIcon className="!w-5 !h-5" />
                    </IconButton>
                  </ToolTip>
                ),
              }}
              extensionRef={panelExtensionRef as RefObject<HTMLDivElement>}
            />
          )}
          <div ref={panelExtensionRef as RefObject<HTMLDivElement>} />
        </div>
      </Drawer>
      {children}
    </SidePanelContext.Provider>
  );
}
