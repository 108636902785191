import React, {
  useMemo,
  MouseEvent,
  ReactNode,
} from 'react';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToolTip, {
  Props as TooltipProps,
} from 'core/components/Tooltip';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import {
  useColorsHashMap,
} from 'pages/Dashboard/pages/Encounters/hooks';

type Props = {
  tagName?: string;
  children?: ReactNode;
  className?: string;
  defaultColor?: string;
  responsive?: boolean;
  hasStripes?: boolean;
  tooltip?: ReactNode | string;
  tooltipProps?: Partial<TooltipProps>;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

export default function Tag({
  children,
  tagName = '',
  className,
  defaultColor = 'bg-emerald-200',
  responsive = false,
  hasStripes = false,
  tooltip,
  // @TODO combine tooltip and tooltip props together
  tooltipProps = {},
  onClick = noop,
}: Props) {
  const colorsHashMap = useColorsHashMap();
  const color = useMemo(() => (
    isNil(tagName)
      ? defaultColor
      : colorsHashMap[tagName] ?? defaultColor
  ), [tagName, colorsHashMap, defaultColor]);
  const isSmallScreen = responsive && useMediaQuery('(max-width:1200px)');
  const classes = clsx(
    'populate-tag py-0.5 px-2 !text-xs rounded-full text-black !border-0',
    color,
    className,
    {
      'has-stripes font-semibold': hasStripes,
      'small:!h-4 small:!min-w-[1rem] small:block small:!text-[0px]': responsive,
    },
  );

  return (
    <ToolTip
      title={tooltip}
      hide={!isSmallScreen && isNil(tooltip)}
      {...tooltipProps}
    >
      <div
        role="button"
        tabIndex={-1}
        className={classes}
        onClick={onClick}
        onKeyDown={noop}
      >
        {children ?? tagName ?? '--'}
      </div>
    </ToolTip>
  );
}
