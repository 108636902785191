import {
  datadogRum,
} from '@datadog/browser-rum';
import {
  datadogApplicationId,
  datadogClientToken,
  environment,
} from 'env';

if (['prod', 'qa'].includes(environment)) {
  datadogRum.init({
    applicationId: datadogApplicationId,
    clientToken: datadogClientToken,
    site: 'us3.datadoghq.com',
    service: 'populateemrapp',
    env: environment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}
