import React, {
  ForwardedRef,
  forwardRef,
} from 'react';
import clsx from 'clsx';
import {
  SnackbarContent,
  CustomContentProps,
  SnackbarKey,
} from 'notistack';
import Alert, {
  Props as AlertProps,
} from 'pages/Dashboard/components/Alert';

export type Props = CustomContentProps
  & Pick<AlertProps, 'className' | 'dismissible' | 'severity' | 'icon'>
  & { handleClose: (id: SnackbarKey) => void };

function Notification({
  message,
  severity,
  handleClose,
  id,
  dismissible,
  className,
  icon,
}: Props, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <SnackbarContent ref={ref} role="alert">
      <Alert
        open
        dismissible={dismissible}
        className={clsx(className, 'w-full')}
        variant="standard"
        severity={severity}
        onClose={() => { handleClose(id); }}
        icon={icon}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
}

export default forwardRef(Notification);
