import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';
import flatMap from 'lodash/flatMap';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import {
  getAge,
  getDefaultIf,
  isEmptyString,
  setDefaultValueIf,
} from 'utils/misc';
import dayjs from 'utils/dayjs';
import {
  DEFAULT_REVIEW_OF_SYSTEM_VALUE,
  getDefaultReviewOfSystemRegex,
} from 'utils/string';
import {
  Patient,
  PatientForm,
} from 'pages/Dashboard/types/patient';
import {
  normalizeInsurance,
  normalizePhoneNumber,
  validatePhone,
} from 'pages/Dashboard/utils/helper';
import {
  formatPatient,
} from 'pages/Dashboard/pages/Charts/helper';
import {
  MedicationBaseDTO,
  PatientDTO,
  PatientVisitDTO,
  PhoneNumberDTO,
  ReviewOfSystemDTO,
  ReviewOfSystemElementDTO,
  SocialHistoryDTO,
  VisitNoteDTO,
} from 'dtos';
import {
  formatDateOnly,
} from 'utils/date';
import {
  PatientVisit,
} from 'pages/Dashboard/pages/Charts/pages/Details/components/VisitsAndNotes';
import orderBy from 'lodash/orderBy';
import {
  getColorByName,
} from 'utils/colors';
import omit from 'lodash/omit';

type ComplexHeight = {
  feet?: number;
  inches?: number;
};

const demographicFields: string[] = [
  'firstName',
  'middleName',
  'lastName',
  'dateOfBirth',
  'genderDescription',
  'occupation',
  'height',
  'weight',
  'email',
  'shoeSize',
];

export const defaultDiscriminators = [
  'Musculoskeletal',
  'Neurological',
  'Cardiovascular',
  'Dermatological',
  'HematologyOncology',
  'Genitourinary',
  'ENT',
  'Respiratory',
  'Gastrointestinal',
];

export const defaultSocialHistoryInfo = {
  tobaccoUsedInLast12Months: null,
  alcoholConsumedInLast12Months: null,
  haveYouEverPutInAnAdvanceCarePlan: null,
  hadOneOrMoreMinorOrFallsInLast12Months: null,
};

export const parseHeightInFeet = (heightInInches: number): ComplexHeight => {
  const feet = Math.floor(heightInInches / 12);
  const inches = Math.floor(heightInInches % 12);
  return { feet, inches };
};

export const convertHeightToInches = ({
  feet = 0,
  inches = 0,
}: ComplexHeight): number | undefined => {
  const height = feet * 12 + inches;
  return height === 0 ? undefined : height;
};

export function normalizeReviewOfSystem(reviewOfSystem: ReviewOfSystemDTO) {
  const groupedByDiscriminator = groupBy(
    reviewOfSystem?.reviewOfSystemElements ?? [],
    'discriminator',
  );
  const groupedElements: Record<string, ReviewOfSystemElementDTO[]> = {};

  defaultDiscriminators.forEach((discriminator) => {
    groupedElements[discriminator] = (discriminator in groupedByDiscriminator
      ? groupedByDiscriminator[discriminator]
      : [{ discriminator, title: DEFAULT_REVIEW_OF_SYSTEM_VALUE, reviewOfSystemElementId: -1 }]
    );
  });

  return groupedElements;
}

export const transformPatient = (patient: PatientDTO): PatientForm => {
  const socialHistory = merge({
    ...defaultSocialHistoryInfo,
  }, patient.socialHistory);
  const { firstName, lastName, state, dateLastSeen } = patient.primaryCarePhysician ?? {};
  const { guardianName, guardianPhoneNumber, guardianEmail } = patient.guardian ?? {};
  return ({
    ...formatPatient(patient) as Patient,
    ...setDefaultValueIf(pick(patient, demographicFields), '', isNil),
    address: {
      ...patient.address,
      ...setDefaultValueIf(patient.address ?? {}, '', isNil),
    },
    insurances: patient.insurances!.map((insurance) => ({
      ...insurance,
      insuranceOwnerDOB: dayjs(insurance.insuranceOwnerDOB),
    })),
    socialHistory,
    ...parseHeightInFeet(patient?.height ?? 0),
    groupedReviewOfSystemElements: normalizeReviewOfSystem(patient.reviewOfSystem!),
    visitNotes: patient.visitNotes?.sort((visitNote1: VisitNoteDTO, visitNote2: VisitNoteDTO) => (
      dayjs(visitNote2.visitDateTime).unix() - dayjs(visitNote1.visitDateTime).unix()
    )),
    stickies: [],
    pinnedStickyCount: patient?.pinnedStickyCount ?? 0,
    acceptsCommunication: patient?.acceptsCommunication ?? false,
    isDeceased: patient?.isDeceased ?? false,
    primaryCarePhysician: {
      ...patient.primaryCarePhysician,
      firstName: getDefaultIf(firstName, '', isNil),
      lastName: getDefaultIf(lastName, '', isNil),
      state: getDefaultIf(state, '', isNil),
      dateLastSeen: getDefaultIf(dateLastSeen, '', isNil),
    },
    guardian: {
      ...patient.guardian,
      guardianName: getDefaultIf(guardianName, '', isNil),
      guardianEmail: getDefaultIf(guardianEmail, '', isNil),
      guardianPhoneNumber: getDefaultIf(guardianPhoneNumber, '', isNil),
    },
  });
};

const serializeReviewOfSystems = (
  groupedReviewOfSystemElements: Record<string, ReviewOfSystemElementDTO[]>,
): ReviewOfSystemElementDTO[] => (
  flatMap(values(groupedReviewOfSystemElements).map((item: ReviewOfSystemElementDTO[]) => {
    const { discriminator } = item[0] ?? {};
    const hasValidValue = item.some((element) => (
      !getDefaultReviewOfSystemRegex(true).test(element.title!.trim())
    ));
    const elements = item.filter((element) => (
      !getDefaultReviewOfSystemRegex(hasValidValue).test(element.title!.trim())
    ));
    const defaultValues = [{
      reviewOfSystemElementId: -1,
      discriminator,
      title: 'Patient Denies',
    }];
    return elements.length > 0 ? elements : defaultValues;
  }))
);

export const serializePatient = (patient: Partial<PatientForm>): Partial<Patient> => {
  const {
    address = {},
    feet,
    inches,
    patientId,
    medications,
    medicalHistories,
    drugAllergies,
    nonDrugAllergies,
    familyHistories,
    socialHistory,
    surgicalHistories,
    reviewOfSystem,
    groupedReviewOfSystemElements,
    insurances = [],
  } = patient;

  const filterKeys = (value: unknown, key: string): boolean => (
    key in patient
    || (key === 'reviewOfSystem' && !isNil(groupedReviewOfSystemElements))
    || (key === 'height' && !isNil(inches) && !isNil(feet))
  );

  return pickBy({
    patientId,
    ...patient,
    ...setDefaultValueIf(
      patient,
      null,
      isEmptyString,
    ),
    insurances: insurances.map(normalizeInsurance),
    address: {
      ...patient.address,
      ...setDefaultValueIf(address, null, isEmptyString),
      country: 'USA',
    },
    height: convertHeightToInches({ feet, inches }),
    socialHistory,
    medications: medications?.map((item: MedicationBaseDTO) => ({
      ...item,
      ...setDefaultValueIf(
        item,
        null,
        isEmptyString,
      ),
    })),
    medicalHistories,
    drugAllergies,
    nonDrugAllergies,
    familyHistories,
    surgicalHistories,
    reviewOfSystem: {
      ...reviewOfSystem,
      reviewOfSystemElements: serializeReviewOfSystems(groupedReviewOfSystemElements!),
    },
  }, filterKeys);
};

export function normalizeSocialHistory(socialHistory: SocialHistoryDTO, dateOfBirth?: string) {
  const underSixtyFiveSocialHistory = {
    tobaccoUsedInLast12Months: socialHistory.tobaccoUsedInLast12Months,
    alcoholConsumedInLast12Months: socialHistory.alcoholConsumedInLast12Months,
  };
  const { value: age } = getAge(dateOfBirth, 'year');
  return age < 65 ? underSixtyFiveSocialHistory : socialHistory;
}

export function normalizeDemographicPayload({
  socialHistory,
  phoneNumber,
  dateOfBirth,
  otherPhoneNumber,
  occupationStartDate,
  primaryCarePhysician,
  guardian,
  ...data
}: Partial<PatientForm>) {
  return {
    ...data,
    ...(!isNil(dateOfBirth)
      ? { dateOfBirth: formatDateOnly(dateOfBirth ?? '') }
      : {}
    ),
    ...(!isNil(socialHistory)
      ? { socialHistory: normalizeSocialHistory(socialHistory, dateOfBirth ?? '') }
      : {}
    ),
    ...(!isNil(occupationStartDate)
      ? { occupationStartDate: formatDateOnly(occupationStartDate ?? '') }
      : {}
    ),
    ...(!isNil(phoneNumber)
      ? {
        phoneNumber: {
          ...phoneNumber as PhoneNumberDTO,
          phoneNumberWithAreaCode: normalizePhoneNumber(phoneNumber.phoneNumberWithAreaCode ?? ''),
        },
      } : {}
    ),
    ...(!isNil(otherPhoneNumber)
      ? {
        otherPhoneNumber: {
          ...otherPhoneNumber,
          phoneNumberWithAreaCode: normalizePhoneNumber(otherPhoneNumber.phoneNumberWithAreaCode ?? ''),
        },
      } : {}
    ),
    ...(!isNil(primaryCarePhysician)
      ? {
        primaryCarePhysician: {
          ...primaryCarePhysician,
          dateLastSeen: dayjs(primaryCarePhysician?.dateLastSeen).isValid() ? formatDateOnly(primaryCarePhysician?.dateLastSeen ?? '') : null,
        },
      } : {}
    ),
    ...(Object.values(omit(guardian ?? {}, 'patientId')).every(isEmptyString) ? {} : {
      guardian: {
        ...guardian,
        guardianPhoneNumber: validatePhone(guardian?.guardianPhoneNumber)
          ? guardian?.guardianPhoneNumber : null,
        guardianName: getDefaultIf(guardian?.guardianName, null, isEmptyString),
        guardianEmail: getDefaultIf(guardian?.guardianEmail, null, isEmptyString),
      },
    }),
  };
}

export function normalizeVisitsForCharts(
  visits: PatientVisitDTO[],
  colorsHashMap: Record<string, string>,
): PatientVisit[] {
  return orderBy(
    visits?.map((item) => ({
      ...item,
      officeColor: getColorByName((colorsHashMap[item.officeLocation?.nickName ?? ''] ?? '!bg-emerald-200').replace('!bg-', '')),
    })),
    'visitDateTime',
    'desc',
  );
}
